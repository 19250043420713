import React from 'react'
import { graphql } from 'gatsby'
import { withIntl } from '../i18n'

import Layout from '../components/Layout'
import ContentBlock from '../components/ContentBlock'
import Img from 'gatsby-image'

const PhotoPage = props => {

  const images = props.data.allImagesJson.edges[0].node.childrenImagesJson.reduce((acc, curr) => {
    acc.push({
      "name": curr.eventName,
      "desc": curr.eventDesc,
      "images": curr.files.reduce((iacc, icurr) => {
        iacc.push(icurr.childImageSharp.fluid)
        return iacc
      }, [])
    })
    return acc
  }, [])

  return (
    <Layout>
      {images.map((t) => (
        <div class="photoGridWrap">
          <div className="photoGridName"> {t.name} </div>
          {t.desc &&
            t.desc.map((tt) => (
              <div className="photoGridDesc">
                {tt}
              </div>
            ))
          }
          <div className={(function(t) {
              switch(t.images.length) {
                case 1:
                  return "photoRow singleColumn";
                case 2:
                  return "photoRow doubleColumn";
                default:
                  return "photoRow multiColumn";
              }
            })(t)
          }>
            {t.images.map((i) => (
              <div className="photoColumn">
                <Img fluid={i} />
              </div>
            ))}
          </div>
        </div>
      ))}

    </Layout>
  )
}

export const PhotosImageQuery = graphql`
  query PhotosImageQuery {
    allImagesJson: allFile(
      filter: { relativePath: { eq: "grid/perf-photos/images.json" } }
    ) {
      edges {
        node {
          childrenImagesJson {
            eventName
            eventDesc
            files {
              childImageSharp {
                fluid(maxWidth: 2280) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default withIntl(PhotoPage)
